<template>
  <div class="purpose">
    <fieldset>
      <legend class="section-title-small">What type of business loan are you interested in?</legend>
     <div class="v-spacer-30" />
     <div class="card-group row-2 input-line">
        <label  
          class="card left" 
          :for="`${id}-business-loans`"
          data-test="ll-wiz-existing-false"
        >
          <input class="sr-only" type="radio" :id="`${id}-business-loans`" :name="`${name}`">
          <div class="card-content">
            <div class="card-title">
              Business Loans
            </div>
            <div class="card-description">
              Take your Business to the next level with a Business loan.
            </div>
          </div>
        </label>
        <label  
          class="card right"
          :for="`${id}-revolving-credit`"
          data-test="ll-wiz-existing-true"
        >
          <input class="sr-only" type="radio" :id="`${id}-revolving-credit`" :name="`${name}`">
          <div class="card-content">
            <div class="card-title">
              Revolving Lines of Credits
            </div>
            <div class="card-description">
              A revolving line of credit from FHB offers working capital to meet these needs and more.
            </div>
          </div>
        </label>
        <label  
          class="card right"
          :for="`${id}-nonprofit-organizations`"
          data-test="ll-wiz-existing-true"
        >
          <input class="sr-only" type="radio" :id="`${id}-nonprofit-organizations`" :name="`${name}`">
          <div class="card-content">
            <div class="card-title">
              Lending to Nonprofit Organizations
            </div>
            <div class="card-description">
              Our experienced bankers offer tailored solutions including tax-exempt financing and lines of credit to bridge receipt of capital campaign pledge proceeds.
            </div>
          </div>
        </label>
        
        <label  
          class="card middle"
          :for="`${id}-commercial-real-estate`"
          data-test="ll-wiz-existing-true"
        >
          <input class="sr-only" type="radio" :id="`${id}-commercial-real-estate`" :name="`${name}`">
          <div class="card-content">
            <div class="card-title">
              Business Real Estate Loan
            </div>
            <div class="card-description">
              Solutions tailored to your objectives.       
            </div>
          </div>
        </label>
      </div>
    </fieldset>
    <div class="button-ctn">
      <button type="button" class="continue-btn" @click="choice">Next</button>
    </div>
  </div>
  
</template>

<script>
export default {
  name: 'QuestionLoanType',
  props: {
    modelValue: String, 
    id: String, 
    name: String,

  },
  methods: {
    choice() {
      if (document.getElementById(`${this.id}-business-loans`).checked) {
        this.$emit('update:modelValue', 'small')
      } else if (document.getElementById(`${this.id}-revolving-credit`).checked) {
        this.$emit('update:modelValue', 'revolving')
      } else if (document.getElementById(`${this.id}-nonprofit-organizations`).checked) {
        this.$emit('update:modelValue', 'nonprofit')
      } else if (document.getElementById(`${this.id}-commercial-real-estate`).checked) {
        this.$emit('update:modelValue', 'realestate')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
