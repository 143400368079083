<template>
  <div class="purpose">
    <div class="input-container ">
      <money-input maxlength="512" class="ll-textfield tf-fname" data-test="ll-bc-filer-fname" type="text" name="cb-business-loan-amount-input" id="cb-business-loan-amount-input" aria-describedby="connect-loanamt-err"
        v-model="requestAmount"
        :class="missingAmount || invalidAmount ? 'll-textfield-error' : 'll-textfield'"
        v-on:blur="validateLoanAmount" required/>
    </div>
    <div class="button-ctn">
      <button type="button" class="continue-btn" @click="choice">Next</button>
    </div>
  </div>
  
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MoneyInput from '@/components/CustomInputs/MoneyInput.vue'
import Validator from '@/app/validation'

export default {
  name: 'QuestionLoanAmount',
  components: {
    MoneyInput,
  },
  props: {
    modelValue: String, 
    id: String, 
    name: String
  },
  data () {
    return {
      missingAmount: false,
      invalidAmount: false
    }
  },
  methods: {
    ...mapActions('questionnaire', [
      'setAmount'
    ]),
    validateLoanAmount() {
      this.missingAmount = this.amount ? false : true
      if (!this.missingAmount) {
        this.invalidAmount = Validator.isValidMoney(this.amount) ? false : true
      }
    },
    choice() {
      const amountFormatted = this.amount.replace('$', '').replaceAll(',', '')
      if (Number(amountFormatted) <= 75000 && Number(amountFormatted) != 0) {
        this.$emit('update:modelValue', '5')
      }
       else if (Number(amountFormatted) <= 500000 && Number(amountFormatted) != 0) {
        this.$emit('update:modelValue', '75')
      }
      else if (Number(amountFormatted) <= 999999 && Number(amountFormatted) != 0) {
        this.$emit('update:modelValue', '500')
      }
      else if (Number(amountFormatted) >= 1000000) {
        this.$emit('update:modelValue', 'mil')
      }
    }
  },
  computed: {
    ...mapGetters('questionnaire', [
      'amount'
    ]),
    requestAmount: {
      get() { return this.amount },
      set(v) { this.setAmount(v) }
    }
  }
}
</script>

<style lang="scss" scoped>
.input-container {
  position: relative;
  max-width: 500px;
  margin: 0 auto;
}  
</style>